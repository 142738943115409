.Toastify__toast-container {
  width: 400px;
}

.Toastify__toast-container--bottom-right {
  right: 2rem;
  bottom: 8rem;
}

.Toastify__toast-container--top-center {
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.Toastify__toast {
  padding: 0;
  border-radius: 8px;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--info {
  background: #1a191e;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
}

.Toastify__progress-bar--error {
  background: #fa5341;
}

.Toastify__progress-bar--success {
  background: #fffd02;
}

.Toastify__progress-bar--info {
  background: #ffffff;
}

.toastBox {
  padding: 10px 10px 18px 10px;
}

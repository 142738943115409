* {
  font-family: "VCR-OSD-MONO", Inter, sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

button {
  user-select: none;
}

html {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.87);
  font-family: "VCR-OSD-MONO";
  font-variant: none;
  font-smooth: always;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body,
#root {
  min-height: 100%;
  background: #0e0024;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 8px;
  background: #000000;
}

body::-webkit-scrollbar-track {
  background: 0 0;
}

body::-webkit-scrollbar-thumb {
  background: #fffd02;
  border-radius: 16px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #fffd02;
}

.container {
  width: 1280px;
  max-width: 100%;
}

.color1 {
  color: rgba(255, 255, 255, 0.87);
}

.color2 {
  color: rgba(255, 255, 255, 0.54);
}

.color3 {
  color: #fffd02;
}

.pointer {
  cursor: pointer;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.mt-8 {
  margin-top: 8px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.border-b {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.16);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.87);
  font-family: "VCR-OSD-MONO";
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
}
input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(255, 255, 255, 0.54);
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.54);
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.54);
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.54);
}

@media only screen and (max-width: 1280px) {
  .container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0 12px;
  }
}

.header-view {
  .header {
    padding: 16px 26px 16px 20px;
    width: 100vw;
  }
}

@media only screen and (max-width: 1280px) {
  .header-view {
    .header {
    }
  }
}

@media only screen and (max-width: 768px) {
  .header-view {
    .header {
    }
  }
}
